<template>
  <div id="modal-calendar-event-form">
    {{/* MODAL FORM */}}
    <b-modal
        id="modal-form-event"
        class="p-0"
        centered
        content-class="shadow"
        :hide-header="true"
        :hide-footer="true"
        body-bg-variant="light"
        body-text-variant="dark"
        @hidden="closeModal"
    >
      <div class="d-flex justify-content-between bg-info p-1">
        <h3 class="text-light ml-2 mt-1">{{ (isEdit) ? 'Editer un événement' : 'Créer un événement' }}</h3>
        <div class="action mt-2">
          <b-icon class="mr-1" icon="x-square" font-scale="1.5" variant="light" v-on:click="closeModal"></b-icon>
        </div>
      </div>
      <div class="form p-3">
        <form @submit.prevent="initSubmit">
          <div class="select-type-event">
            <label for="select-type-event">Choisir un type d'événement</label>
            <select
                id="select-type-event"
                class="form-control"
                v-model="selectEventType"
                v-on:change="onChangeTypeEvent"
            >
              <option disabled="disabled">Choisir un type d'événement</option>
              <option v-for="(eventType, index) in listEventTypes" :value="eventType.id" :key="index">
                Evénement de {{ eventType.name }}
              </option>
            </select>
          </div>

          <div class="form-group mt-2" v-if="typeEvent && typeEvent.slug === 'center'">
            <label for="select-center">Centre</label>
            <select id="select-center" class="form-control" v-model="event.center">
              <template v-for="(center) in CenterModule.centers" :value="center.id">
                <option :key="center.id" :value="center.id">
                  {{ center.city }}
                </option>
              </template>
            </select>
            <div v-if="errorsForm.center">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.center.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group mt-2" v-if="typeEvent && typeEvent.slug === 'category'">
            <label for="select-category">Categorie</label>
            <select id="select-category" class="form-control" v-model="event.category">
              <template v-for="(category) in CategoryModule.categories">
                <option :key="category.id" :value="category.id" :class="(category.childs.length === 0) ? 'category-child' : 'category-parent' ">
                  {{ category.name }}
                </option>
              </template>
            </select>
            <div v-if="errorsForm.category">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.category.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          {{ /* AUTOCOMPLETE SHOP */ }}
          <div class="form-group mt-2" v-if="typeEvent && typeEvent.slug === 'shop'">
            <label for="select-category">Magasin(s)</label>
            <AutoComplete
                class=""
                :suggestions="ShopModule.shops"
                :research-label="'magasin(s)'"
                :placeholder="'Trouvez un magasin'"
                entity="shop"
                @click-suggestion="clickSuggestionAutocomplete"
            />
          </div>

          {{ /* Badge autocomplete */ }}
          <div class="d-flex flex-wrap mb-4 w-100" v-if="typeEvent && typeEvent.slug === 'shop' && listBadgeAutoComplete.length > 0">
            <div v-for="(searchObj, index) in listBadgeAutoComplete" :key="index">
              <b-badge class="p-2 c-pointer mt-2 ml-2" variant="dark" v-on:click="removeBadgeAutoComplete(searchObj)">
                {{ searchObj.result.name }} | {{ searchObj.result.center.name }}
                <b-icon
                    class="ml-2"
                    icon="x"
                    font-scale="1.5"
                    variant="light"
                >
                </b-icon>
              </b-badge>
            </div>
          </div>

          <div class="form-group mt-2" v-if="typeEvent">
            <label for="title">Titre : *</label>
            <input class="form-control" id="title" v-model="event.title" type="text" required>
            <div v-if="errorsForm.title">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.title.errors" :key="index">
                {{ error }}
              </p>
            </div>
          </div>

          <div class="form-row" v-if="typeEvent">
            <div class="col">
              <label for="date-start">Date de début</label>
              <Datepicker
                  id="date-start"
                  inputClass="form-control bg-white"
                  v-model="event.start"
                  :format="formatDate"
                  :language="datepickerLanguage"
                  :open-date="startDate"
              ></Datepicker>
              <div v-if="errorsForm.startDate">
                <p class="form-error text-danger" v-for="(error, index) in errorsForm.startDate.errors" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <div class="col">
              <label for="date-end">Date de fin</label>
              <template v-if="isEdit">
                <Datepicker
                    id="date-end"
                    inputClass="form-control bg-white"
                    v-model="eventEndDate"
                    :format="formatDate"
                    :language="datepickerLanguage"
                    :open-date="endDate"
                ></Datepicker>
              </template>
              <template v-else>
                <Datepicker
                    id="date-end"
                    inputClass="form-control bg-white"
                    v-model="event.end"
                    :format="formatDate"
                    :language="datepickerLanguage"
                    :open-date="endDate"
                ></Datepicker>
              </template>

              <div v-if="errorsForm.endDate">
                <p class="form-error text-danger" v-for="(error, index) in errorsForm.endDate.errors" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
          </div>

          <div class="form-group mt-2" v-if="typeEvent && typeEvent.slug === 'shop'">
            <label for="select-event">Type d'événement</label>
            <select
                id="select-event"
                class="form-control"
                v-model="event.eventShopType"
                v-on:change="onChangeShopTypeEvent"
            >
              <option v-for="(event, index) in listEventShopTypes" :key="index" :value="event.id">
                {{ event.name }}
              </option>
            </select>
            <div v-if="errorsForm.parent">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.parent.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div
              class="form-group mt-3"
              v-if="typeEvent
                && typeEvent.slug === 'shop'
                && ((eventShopTypeSelected === 1 || event.eventShopType === 1)
                || (eventShopTypeSelected === 3 || event.eventShopType === 3))
              "
          >
            <div class="alert alert-info" role="alert">
              <b>ATTENTION</b> : les dates suivantes, seront retirées des comparaisons sur le chiffre d'affaires !
            </div>
            <div class="form-row">
              <div class="col">
                <label>Date(s)</label>
                <hr  class="mt-0"/>
                <div v-if="isEdit && event.eventDates.length > 0">
                  <div v-for="(datepicker, index) in listDatePicker" :key="index">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex">
                        <div>
                          <label>Date de début</label>
                          <Datepicker
                              :id="'datepicker-' + datepicker"
                              inputClass="form-control bg-white"
                              :format="formatDate"
                              :language="datepickerLanguage"
                              :key="index"
                              v-model="event.eventDates[index].startDate"
                              @selected="clickDatePickerListDate($event, index, true)"
                          ></Datepicker>
                        </div>

                        <div class="ml-2 mr-2">
                          <label>Date de fin</label>
                          <Datepicker
                              :id="'datepicker-' + datepicker"
                              inputClass="form-control bg-white"
                              :format="formatDate"
                              :language="datepickerLanguage"
                              :key="index"
                              v-model="event.eventDates[index].endDate"
                              @selected="clickDatePickerListDate($event, index, false)"
                          ></Datepicker>
                        </div>
                      </div>

                      <div class="mt-2">
                        <div class="d-flex mt-4">
                          <span v-if="(isEdit) ? listDatePicker > 1 && index > 0 : index > 0" class="btn btn-danger btn-sm mr-2">
                            <b-icon class="" icon="dash" font-scale="1" variant="white" v-on:click.prevent="removeDatepicker(index)"></b-icon>
                          </span>
                          <span class="btn btn-primary btn-sm">
                            <b-icon class="" icon="plus" font-scale="1" variant="white" v-on:click.prevent="addDatepicker"></b-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(datepicker, index) in listDatePicker" :key="index">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex">
                        <div>
                          <label>Date de début</label>
                          <Datepicker
                              :id="'datepicker-' + datepicker"
                              inputClass="form-control bg-white"
                              :format="formatDate"
                              :language="datepickerLanguage"
                              :key="index"
                              @selected="clickDatePickerListDate($event, index, true)"
                          ></Datepicker>
                        </div>

                        <div class="ml-2 mr-2">
                          <label>Date de fin</label>
                          <Datepicker
                              :id="'datepicker-' + datepicker"
                              inputClass="form-control bg-white"
                              :format="formatDate"
                              :language="datepickerLanguage"
                              :key="index"
                              @selected="clickDatePickerListDate($event, index, false)"
                          ></Datepicker>
                        </div>
                      </div>

                      <div class="mt-2">
                        <div class="d-flex mt-4">
                          <span v-if="(isEdit) ? listDatePicker > 1 && index > 0 : index > 0" class="btn btn-danger btn-sm mr-2">
                            <b-icon class="" icon="dash" font-scale="1" variant="white" v-on:click.prevent="removeDatepicker(index)"></b-icon>
                          </span>
                          <span class="btn btn-primary btn-sm">
                            <b-icon class="" icon="plus" font-scale="1" variant="white" v-on:click.prevent="addDatepicker"></b-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mt-2" v-if="typeEvent">
            <label for="reason-textarea">Commentaire : *</label>
            <textarea class="form-control" id="reason-textarea" rows="3" v-model="event.comment" required></textarea>
          </div>

          <input
              type="submit"
              class="btn mt-4 btn-success"
              :value="(isEdit) ? 'editer un événement' : 'Créer un événement'"
              v-if="typeEvent"
          />
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'
import { mapState } from 'vuex'

export default {
  props: {
    selectedEvent: {
      type: Object,
      immediate: true
    },
    selectedEventShopType: {
      type: Number,
      immediate: true
    },
    selectedEventType: {
      type: Number,
      immediate: true
    },
    startDate: Date,
    endDate: Date,
    isEdit: {
      type: Boolean,
      immediate: true
    }
  },
  data() {
    return {
      typeEvent: null,
      event: {
        shops: [],
        startDate: null,
        endDate: null
      },
      errorsForm: [],
      formatDate: 'dd/MM/yyyy',
      datepickerLanguage: fr,
      listDatePicker: 1,
      countDatepicker: 1,
      listBadgeAutoComplete : [],
      listEventShopTypes: [],
      listEventTypes: [],
      eventEndDate: null,
      selectEventType: this.selectedEventType,
      eventShopTypeSelected: this.selectedEventShopType,
      tempDatepickerValue: '',
      formEventDate: {
        startDate : null,
        endDate: null
      },
    }
  },
  created() {
    this.$store.dispatch('fetchCategories', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchCenters', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchShops', {
      perPage: null,
      page: null
    })

    this.typeEvent = (this.selectedEvent && this.selectedEvent.eventType) ? this.selectedEvent.eventType : this.typeEvent

    // Fetch event Types for Shop
    this.$store.dispatch('fetchEventTypes', 'shop_type')
      .then((response) => {
        this.listEventShopTypes = response
      })
    // Fetch event types
    this.$store.dispatch('fetchEventTypes', null)
      .then((response) => {
        this.listEventTypes = response
      })
  },
  watch: {
    selectedEventType: function() {
      this.selectEventType = this.selectedEventType
    },
    selectedEventShopType: function(newValue) {
      this.eventShopTypeSelected = parseInt(newValue)
    },
    countDatepicker: function() {
      this.listDatePicker = this.countDatepicker
    },
    startDate: function() {
      this.event.start = moment(this.startDate).format('YYYY-MM-DD')
    },
    endDate: function() {
      this.event.end = moment(this.endDate).format('YYYY-MM-DD')
    },
    selectedEvent: function() {
      this.typeEvent = (this.selectedEvent && this.selectedEvent.type) ? this.selectedEvent.type : this.typeEvent
      if (this.selectedEvent) {
        this.event = this.selectedEvent
        // Handle endDate
        this.eventEndDate = moment(this.event.end).subtract(1, 'days').format('YYYY-MM-DD')
        // Handle listDates
        if (this.event.eventDates && this.event.eventDates.length > 0) {
          this.listDatePicker = this.event.eventDates.length
        }

        this.listBadgeAutoComplete = this.handleListBadgeAutoCompleteOnEdit(this.event)
      } else {
        this.event = {
          title: null,
          start: null,
          end: null,
          comment: null,
          type: null,
          user: null,
          center: null,
          category: null,
          eventType: null,
          eventShopType: null,
          shops: [],
          listDates: []
        }
      }
    }
  },
  methods: {
    /*-----------------ACTION MODAL--------------------*/
    editActionModal()
    {
      this.$emit('emit-edit-action')
    },
    deleteActionModal()
    {
      this.$emit('emit-delete-action')
    },
    closeModal()
    {
      // reset var
      this.countDatepicker = 1
      this.listDatePicker = 1

      if(this.isEdit) {
        this.$bvModal.show('modal-data-event')
      } else {
        this.listBadgeAutoComplete = []
        this.typeEvent = null
      }
      this.$bvModal.hide('modal-form-event')

      this.$emit('emit-close-modal', this.isEdit)
    },
    /*-----------------ON CHANGE TYPE EVENT--------------------*/
    onChangeTypeEvent($event) {
      this.typeEvent = this.listEventTypes.find(eventType => eventType.id === Number($event.target.value))
      if (this.typeEvent.slug === 'shop') {
        this.$store.dispatch('fetchEventTypes', true)
      }
    },
    /*-----------------ON CHANGE SHOP TYPE EVENT--------------------*/
    onChangeShopTypeEvent($event) {
      if ($event.target.value === 1 || $event.target.value === 3) {
        this.event.eventDates.push({
          index: 0,
          startDate: null,
          endDate: null
        })
      } else {
        this.event.eventDates = []
      }

      this.event.eventShopType = parseInt($event.target.value)
      this.eventShopTypeSelected = parseInt($event.target.value)
    },
    /*-----------------DATE--------------------*/
    addDatepicker() {
      this.listDatePicker = this.listDatePicker + 1
    },
    removeDatepicker(index) {
      // Remove form list
      let indexDate = this.event.eventDates.findIndex(obj => obj.index === index)
      if (indexDate > -1) {
        this.event.eventDates.splice(indexDate, 1)
      }

      this.listDatePicker = this.listDatePicker - 1
    },
    clickDatePickerListDate($event, index, isStartDate) {
      let getDate = this.event.eventDates[index]
      if (getDate !== undefined) {
        if (isStartDate) {
          this.event.eventDates[index].startDate = moment($event).format('yyyy-MM-DD')
        } else {
          this.event.eventDates[index].endDate = moment($event).format('yyyy-MM-DD')
        }
      } else {
        this.listDateCreated($event, index, isStartDate)
      }
    },
    listDateCreated($event, index, isStartDate) {
      if(isStartDate) {
        this.formEventDate.startDate = moment($event).format('yyyy-MM-DD')
      } else {
        this.formEventDate.endDate = moment($event).format('yyyy-MM-DD')
      }

      let model = {
        index: index,
        startDate: this.formEventDate.startDate,
        endDate: this.formEventDate.endDate
      }

      if(model.startDate && model.endDate) {
        let indexDate = this.event.eventDates.findIndex(obj => obj.index === index)
        if (indexDate > -1) {
          this.event.eventDates.splice(indexDate, 1)
        }

        this.event.eventDates.push(model)
      }
    },
    /*-----------------AUTOCOMPLETE--------------------*/
    clickSuggestionAutocomplete(model) {
      let index = this.event.shops.findIndex(shop => shop === model.result.id)

      if(index > -1) {
        this.event.shops.splice(index, 1)
        this.listBadgeAutoComplete.splice(index, 1)
      } else {
        this.event.shops.push(model.result)
        this.listBadgeAutoComplete.push(model)
      }
    },
    removeBadgeAutoComplete(searchObj) {
      let index = this.event.shops.findIndex(shop => shop === searchObj.result.id)

      this.event.shops.splice(index, 1)
      this.listBadgeAutoComplete.splice(index, 1)
    },
    handleListBadgeAutoCompleteOnEdit(event) {
      let array = []
      if (event && event.shops) {
        event.shops.forEach(shop => {
          let model = {
            checked: PointerEvent,
            entity:"shop",
            result: shop
          }

          array.push(model)
        })
      } else {
        array = []
      }

      return array
    },
    /*-----------------FORM--------------------*/
    initSubmit()
    {
      // Set var
      this.event.user = this.$store.getters.getCurrentUser.id
      this.event.eventType = this.typeEvent.id
      if(!this.event.end) this.event.end = this.eventEndDate
      let model = {
        event: this.event,
        isEdit: this.isEdit
      }

      // Emit
      this.$emit('emit-submit-form', model)
      this.closeModal()
    }
  },
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule'])
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    AutoComplete: () => import('@/components/_shared/AutoComplete')
  }
}
</script>
